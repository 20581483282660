import VueI18n from 'vue-i18n'
import Vue from 'vue'
import { PaymentTypes } from './store/payments/types'

// ensure plugin is installed!
Vue.use(VueI18n)

export default new VueI18n({
  locale: 'en-GB',
  fallbackLocale: 'en',
  numberFormats: {
    'en-GB': {
      currency: {
        style: 'currency', currency: 'GBP'
      },
      money: {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: false
      }
    }
  },
  messages: {
    'en-GB': {
      module: 'no modules | one module | {count} modules',
      maths: 'Maths',
      literacy: 'Literacy',
      mathsGCSE: 'GCSE Revision',
      mathsN5: 'N5 Revision',
      reading: 'Reading',
      APS_SUBSCRIPTION: 'APS',
      APS: 'APS',
      LTL: 'Finance',
      PAYG: 'PAYG',
      CASH: 'Cash',
      CP12: 'CP12 Annual Subscription (Paid in full)',
      SP01: 'SP01 Subscription',
      SP06: 'SP06 Subscription',
      SP12: 'SP12 Subscription',
      LTS10: 'LTS10 Lifetime Subscription',
      SP03: 'SP03 GCSE Subscription',
      SP30: 'SP30 Subscription',
      CREDITCARD: 'Credit Card',
      DEFERRED: 'Deferred Deposit',
      CHEQUE: 'Cheque',
      year: 'less than 1 year | 1 year | {count} years',
      month: 'less than 1 month | 1 month | {count} months',
      payments: {
        hint: {
          'exemplarsite-card': 'Create a credit card payment which is processed externally',
          cash: 'Manually processed payment to be completed later with customer',
          cheque: 'Create a Cheque payment',
          deferred: 'Manually processed Deferred Initial Subscription payment',
          'creditcard-stripe': 'Process a Credit Card payment right in IRIS',
          'deferred-stripe': 'Create a Deferred Initial Subscription payment',
          'payment-plan-stripe': 'Create a Payment plan via Credit Card',
          'manual-refund': 'Adjust the deposit amount with a manual Refund',
          'deferred-recurly': 'Create a Deferred Initial Subscription payment',
          'deferred-recurly-remote': 'Create a Deferred Initial Subscription payment - Collect card details on customer end',
          'creditcard-recurly': 'Process a Credit Card payment right in IRIS',
          'creditcard-recurly-remote': 'Send a link to the customer to get their credit card details',
          'payment-plan-recurly': 'Create a Payment plan via Credit Card',
          klarna: 'Pay in 4 instalments - process on Klarna site'
        } as Record<PaymentTypes, string>,
        title: {
          'exemplarsite-card': 'Manual Credit Card',
          cash: 'Manual',
          cheque: 'Cheque',
          deferred: 'Manual D.I.S.',
          'creditcard-stripe': 'Credit Card',
          'deferred-stripe': 'D.I.S.',
          'payment-plan-stripe': 'Payment Plan',
          'manual-refund': 'Manual Refund',
          'remote': 'Credit Card NDCC',
          'deferred-recurly': 'D.I.S.',
          'deferred-recurly-remote': 'Remote D.I.S.',
          'creditcard-recurly': 'Credit Card',
          'creditcard-recurly-remote': 'Remote Credit Card',
          'payment-plan-recurly': 'Payment Plan',
          klarna: 'Klarna'
        } as Record<PaymentTypes, string>
      }
    }
  }
})
